<template>
  <div class="sub-pilot-list row">
    <div class="col-12 pt-2">
      <h6>Flight List</h6>
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Select date range for flight listing</h4>
          <div class="row mb-sm-2">
            <div class="col-sm-4 mb-3">
              <b-form-datepicker
                id="startDate"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                placeholder="Choose start date"
                local="en"
                v-model="startDate"
              ></b-form-datepicker>
            </div>
            <div class="col-sm-4 mb-3">
              <b-form-datepicker
                id="endDate"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                placeholder="Choose end date"
                local="en"
                v-model="endDate"
              ></b-form-datepicker>
            </div>
            <div class="row ml-1">
              <div class="col-3">
                <b-button
                  variant="light"
                  size="sm"
                  @click="getFlightList()"
                  :disabled="isDisable"
                  ><i class="uil uil-check font-18 text-success"></i
                ></b-button>
              </div>
              <div class="col-9 justify-content-end" v-if="rows > 0">
                <b-button
                  variant="light"
                  size="sm"
                  @click="csvExport"
                  :disabled="selectedFlight.length == 0"
                  ><i class="font-18 text-success">Export to CSV</i></b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-5">
              <h4 class="header-title mt-2">Flight</h4>
            </div>
            <div class="col-7 mb-3 d-flex justify-content-sm-end">
              <b-button
                variant="light"
                size="sm"
                @click="selectAllflight"
                :disabled="isSelectAllBtn"
              >
                {{ ordersSelectBtn }}
              </b-button>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              striped
              :items="getFlights"
              ref="table"
              :fields="fields"
              :no-provider-sorting="true"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              @row-clicked="onRowSelected"
              :tbody-tr-class="selectedRowStyle"
            >
              <template v-slot:cell(selected)="{ item, field: { key } }">
                <b-checkbox
                  v-model="item[key]"
                  @change="checkselected(item)"
                  size="lg"
                />
              </template>
              <template v-slot:cell(wc_order_id)="row">
                {{ row.item.wc_order_id }}
              </template>
              <template v-slot:cell(flight_time)="row">
                {{ formatDateTime(row.item.flight_time) }}
                <br />
                <span> {{ formatTime(row.item.flight_time) }} </span>
              </template>

              <template v-slot:cell(passenger)="row">
                {{ row.item.passenger.firstname }}
                {{ row.item.passenger.lastname }}
                <br />
                {{ row.item.passenger.gender }} |
                {{ calculateAge(row.item.passenger.dob) }}
              </template>

              <template v-slot:cell(contact)="row">
                {{ row.item.pilot.user.phone_no }}
                <br />
                {{ row.item.wc_order.order_data.pickupLocation }}
              </template>

              <template v-slot:cell(flight_type)="row">
                {{ row.item.flight_type }}
                <br />
                {{ row.item.pilot.operator.zone.name }}
              </template>

              <template v-slot:cell(pilot)="row">
                {{ row.item.pilot.user.name }}
                <br />
                {{ row.item.pilot.operator.company_name }}
              </template>

              <template v-slot:cell(status)="row">
                {{ row.item.wc_order.order_data.bookingStatus }}
                <br />
                ₹{{ row.item.wc_order.order_data.totalPayment }} receivable
              </template>

              <template v-slot:cell(actions)>
                <a title="View" class="action-icon view">
                  <i class="mdi mdi-eye"></i>
                </a>
              </template>

              <template v-slot:row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </div>
          <div class="row">
            <!-- # of entries per page selector -->
            <div class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="ml-2 mr-2"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="dataTables_paginate paging_simple_numbers">
                <b-pagination
                  class="justify-content-center justify-content-sm-end"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @input="selectedPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>

<script>
import { GET_FLIGHT_LIST } from "@/graphql/queries/queries";
import { DateTime } from "luxon";
import _ from "lodash";

export default {
  name: "flightListing",
  data() {
    return {
      flightList: {},
      selectedFlight: [],
      isSelectAllflights: false,
      isSelectAllBtn: true,
      flightCSV: [],
      offsetValue: 0,
      startDate: null,
      endDate: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "flight_id",
          label: "Flight ID",
          class: "pilotID",
          sortable: true,
        },
        {
          key: "wc_order_id",
          label: "Order ID",
          class: "pilotID",
          sortable: true,
        },
        {
          key: "flight_time",
          label: "Date/Time",
          tdClass: "dateTime",
          sortable: true,
        },
        { key: "passenger", label: "Passenger" },
        { key: "contact", label: "Contact"},
        { key: "flight_type", label: "Flight Type" },
        { key: "pilot", label: "Pilot" },
        { key: "status", label: "Status" },
        { key: "actions", label: "actions" },
      ],
    };
  },
  computed: {
    ordersSelectBtn() {
      return this.isSelectAllflights
        ? "Unselect All Flights"
        : "Select All Flights";
    },
    isDisable() {
      return this.startDate === null || this.endDate === null ? true : false;
    },
    rows() {
      if (Object.keys(this.flightList).length > 0) {
        return this.flightList.flights_aggregate.aggregate.count;
      }
      return 0;
    },
  },
  watch: {
    perPage() {
      //set the offset value to default whenever show entries get changes (i.e when limit gets changed)
      this.offsetValue = 0;
      this.getFlightList();
    },
    flightList() {
      this.$refs.table.refresh();
    },
  },
  methods: {
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_FULL);
    },
    formatTime(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toFormat("h:mm a");
    },
    calculateAge(dob) {
      let d1 = DateTime.local();
      let d2 = DateTime.fromISO(dob);
      let age = d1.diff(d2, ["year", "months", "days"]).toObject();
      return age.years;
    },
    getFlightList() {
      this.isSelectAllBtn = false;
      this.$apollo.queries.flightList.skip = false;
      this.$apollo.queries.flightList.refetch();
      // set "selected" property value to false before selecting row
      _.forEach(this.flightList.flights, (flight) => {
        this.$set(flight, "selected", false);
      });
    },
    selectedPage() {
      this.offsetValue = (this.currentPage - 1) * this.perPage;
      this.getFlightList();
    },
    getFlights() {
      return this.flightList.flights || [];
    },
    checkselected(item) {
      if (item.selected && !_.includes(this.selectedFlight, item.flight_id)) {
        //console.log("item: ", item.flight_id);
        this.selectedFlight.push(item);
        console.log(this.selectedFlight);
      } else {
        this.selectedFlight.splice(
          this.selectedFlight.indexOf(item.flight_id),
          1
        );
      }
    },
    onRowSelected(item) {
      if (item.selected) {
        this.$set(item, "selected", false);
        this.selectedFlight.splice(
          this.selectedFlight.indexOf(item.flight_id),
          1
        );
      } else {
        this.$set(item, "selected", true);
        this.selectedFlight.push(item);
      }
    },
    selectAllflight() {
      this.selectedFlight = [];
      this.isSelectAllflights = !this.isSelectAllflights;

      this.flightList.flights.forEach((flight) => {
        if (!this.isSelectAllflights) {
          this.$set(flight, "selected", false);
        } else {
          this.$set(flight, "selected", true);
          this.selectedFlight.push(flight);
        }
      });
    },

    selectedRowStyle(item, type) {
      if (!item || type !== "row") return;
      if (item.selected) {
        return ["b-table-row-selected", "table-primary", "cursor-pointer"];
      } else {
        return ["cursor-pointer"];
      }
    },
    csvExport() {
      const dt = DateTime.local();
      let csvContent = "data:text/csv;charset=utf-8,";
      const csvHeaders = [
        "Registration Date",
        "Policy Start Date",
        "Title",
        "First Name",
        "Last Name",
        "Date of Birth",
        "Mobile Number",
        "Email",

        // fields not required
        "Address 1",
        "Address 2",
        "Nationality",
        "Nominee Name",
        "Relationship with Nominee",
        "ID Proof",
        "Pre Existing Disease",
        "Disease Name",
        "I.D.Proof number",

        // static data
        "Product Name",
        "Price",
        "Variation",
      ];
      const csvData = _.map(this.selectedFlight, (o) => {
        const pax = [];
        pax.push(dt.toISODate());
        pax.push(
          DateTime.fromISO(o.wc_order.order_data.startTime).toUTC().toISODate()
        );
        const title =
          o.passenger.gender === "Male"
            ? "Mr"
            : o.passenger.gender === "Female"
            ? "Ms"
            : "Others";
        pax.push(title);
        pax.push(o.passenger.firstname);
        pax.push(o.passenger.lastname);
        pax.push(o.passenger.dob);
        pax.push(o.passenger.phone);
        pax.push(o.passenger.email);
        // fields not required
        for (let i = 0; i < 9; i++) {
          pax.push("");
        }
        // static fields
        pax.push("Paragliding");
        pax.push("150");
        pax.push("basic");
        return pax;
      });
      csvContent += csvHeaders.join(",") + "\n";
      csvContent += _.join(
        _.map(csvData, (d) => {
          return _.join(d, ",");
        }),
        "\n"
      );
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      const fileName = `pb-paxdata-${DateTime.local().toISO()}.csv`;
      link.setAttribute("download", fileName);
      link.click();
    },
  },
  apollo: {
    flightList: {
      // graphql query
      query: GET_FLIGHT_LIST,
      variables() {
        return {
          from: this.startDate,
          to: DateTime.fromISO(this.endDate)
            .plus({ days: 1 })
            .toLocaleString(DateTime.DATE_FULL),
          limit: this.perPage,
          offset: this.offsetValue,
        };
      },
      update(data) {
        this.$apollo.queries.flightList.skip = true;
        return data;
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
      skip: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/sass_files";

.sub-pilot-list {
  table.table {
    color: #6c757d;
  }

  td.pilot-name {
    font-weight: 600;
  }

  td.operator span,
  td.pilot-name span {
    display: block;
    font-size: 13px;
    color: #98a6ad;
    font-weight: 400;
  }

  .action-icon {
    display: inherit;
    padding: 0 5px;
    font-size: 1.2rem;
    color: #98a6ad;
    cursor: pointer;
  }

  .action-icon:hover {
    color: #6c757d;
  }

  .action-icon.edit {
    margin-left: 10px;
  }

  h4.header-title {
    display: inline-block;
    vertical-align: sub;
    margin-right: 30px;
  }
}
</style>
